@import "../src/styles/variables.scss";

body {
  margin: 0;
  font-family: "Figtree", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Figtree";
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
  // @include scrollbar($Placeholder_Text, $gray_extra_light);
}

html {
  overflow: hidden;
}

body {
  font-weight: 500;
  color: $Font_Primary;
  font-size: 16px;
  line-height: 1.3;
  font-style: normal;
  background-color: $white;
  margin: 0;
  padding-right: 0px !important;

  a {
    text-decoration: none;
    color: $Font_Primary;
  }

  button {
    background: none;
    border: none;
  }

  #root {
    height: 100%;
  }
}
