@import "../../../styles/variables.scss";

.signUpPageContainer {
  max-width: 414px;
  max-height: calc(100% - 64px);
  width: 100%;
  margin: 0 auto;
  border-radius: 24px;
  padding: 24px;
  background-color: $white;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden auto;

  .logoContainer {
    width: 164.64px;
    height: 64px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .signUpHeading {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 353px;
    width: 100%;
    margin: 0 auto;

    h3 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
      color: $Font_Primary;
    }

    p {
      letter-spacing: 0.04em;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }

  .form-control {
    margin-bottom: 20px;
  }

  .newPassword {
    &.not-valid {
      margin-bottom: 0;
      .error-message {
        text-align: left;
        position: relative;
        line-height: 1.2;
      }
      .toggle-visible {
        top: 17px;
        transform: translateY(0);
      }
    }
  }

  .btnWrapper {
    margin-top: 24px;
  }

  .signInLink {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    p {
      color: $dark_gray;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      a {
        color: $Blue_Primary;
        text-decoration: underline;
      }
    }
  }
}
