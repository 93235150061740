@import "../../styles/variables.scss";

.loader-container {
  position: relative;
  height: 100%;
  width: 100%;
  .loader-inner-container {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease-in-out;
  }

  .loader-logo {
    transform: scale(0.6);
  }

  .loader-container:not(.active) {
    animation: fadeOut 0.5s forwards;
  }

  .dbl-spinner {
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: transparent;
    border: 4px solid transparent;
    border-top: 4px solid $Secondary_Text;
    border-left: 4px solid $Secondary_Text;
    -webkit-animation: 2s spin linear infinite;
    animation: 2s spin linear infinite;
  }

  .dbl-spinner--2 {
    border: 4px solid transparent;
    border-right: 4px solid $Blue_Primary;
    border-bottom: 4px solid $Blue_Primary;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }
}
