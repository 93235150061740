.outerLayoutContainer {
    position: relative;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    .backgroundContainer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .outletContainer {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}