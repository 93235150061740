@import "/src/styles/variables.scss";

.empty-placehoder {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 250px;
  justify-content: center;
  align-items: center;

  .placeholder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .placeholder-title {
      font-size: 24px;
      font-weight: 400;
      line-height: 150%;
      color: $Secondary_Text;
    }
  }

  // Below 1199px viewport
  @media screen and (max-width: $screen-md-max) {
    .placeholder-container {
      .placeholder-title {
        font-size: 20px;
      }
    }
  }

  // Below 1199px viewport
  @media screen and (max-width: $screen-1199) {
    .placeholder-container {
      .placeholder-title {
        font-size: 18px;
      }
    }
  }
}
