@import "/src/styles/variables.scss";

@mixin p-styles {
  font-size: 14px;
  letter-spacing: 0.5px;
}

.subscription-container {
  @include scrollbar($Placeholder_Text, $gray_extra_light);
  padding: 30px 50px;
  text-align: left;
  scroll-behavior: smooth;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  
  &.align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subscription-inner {
    margin: 0 auto;
    
    &.max-width {
      max-width: 500px;
      width: fit-content;
    }

    .header {
      margin-bottom: 24px;
      font-weight: 700;
      
      .title {
        font-size: 28px;
        margin-bottom: 10px;
        color: $Blue_Primary;
      }

      p {
        @include p-styles;
        color: $Secondary_Text;
      }
    }

    .payment-section {
      margin-bottom: 24px;
      
      h4 {
        font-size: 20px;
        margin-bottom: 8px;
        color: $Font_Primary;
      }

      p {
        @include p-styles;
        color: $Secondary_Text;
        margin-bottom: 16px;
      }
    }

    .manage-subscription-container {
      p {
        @include p-styles;
        margin-bottom: 12px;
        color: $Secondary_Text;
      }

      Button {
        max-width: 380px;
      }
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    .subscription-inner {
      .header {
        .title {
          font-size: 22px;
        }
        p {
          font-size: 12px;
        }
      }
      .payment-section {
        h4 {
          font-size: 18px;
        }
        p {
          font-size: 12px;
        }
      }
      .manage-subscription-container {
        p {
          font-size: 12px;
        }
      }
    }
  }
}
