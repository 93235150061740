@import "../../styles/variables.scss";

.button {
  position: relative;
  padding: 16px;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  height: 45px;
  line-height: 24px;
  letter-spacing: 4px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
  white-space: nowrap;
  border-radius: 8px;
  border: none;
  width: 100%;
  letter-spacing: 0.04em;

  &.primary {
    background-color: $Blue_Primary;
    color: $white;
  }

  &.primary-outline {
    background-color: $white;
    border: 1px solid $Blue_Primary;
  }

  &.orange-light {
    background-color: $Light_Accent;
    color: $Blue_Primary;
  }

  &.gray-light {
    background-color: $white;
    color: $Secondary_Text;
  }

  &.gray-dark {
    background-color: $Light_Text_BG;
    color: $Placeholder_Text;
    cursor: not-allowed;
  }

  &.purple-dark {
    background-color: $Accent;
    color: $white;
  }

  &.purple-light {
    background-color: $Secondary_light;
    color: $Accent;
  }

  &.default-purple {
    padding: 0px 0px;
    color: $Accent;
  }

  &.default-gray {
    padding: 0px 0px;
    color: $Placeholder_Text;
  }

  // Below 1199px viewport
  @media screen and (max-width: $screen-1199) {
    font-size: 14px;
  }

  &.animation {
    background-color: #f8f9fa;
    color: #8b93a1;
    cursor: not-allowed;

    span {
      opacity: 1;
      z-index: 1;
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efefefe3;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s;

    &:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid #4579ff;
      border-top-color: transparent;
      animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
}