.verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.success-message,
.error-message {
  background-color: #f0f4f8;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.success-message h2 {
  color: #28a745;
}

.error-message h2 {
  color: #dc3545;
}
