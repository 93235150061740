@import "../../../styles/variables.scss";
.Toastify__toast--error {
  .Toastify__toast-body::before {
    content: "";
    display: inline-block;
    margin-right: 10px;
    width: 4px;
    height: 58px;
    border-radius: 50px;
    background-color: $Error;
  }
  .Toastify__progress-bar--error {
    background-color: $Error;
  }
}
