@import "../src/styles/variables.scss";

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: "Figtree";
  src: url("../src/assets/fonts/Figtree-Light.eot");
  src: url("../src/assets/fonts/Figtree-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/Figtree-Light.woff2") format("woff2"),
    url("../src/assets/fonts/Figtree-Light.woff") format("woff"),
    url("../src/assets/fonts/Figtree-Light.ttf") format("truetype"),
    url("../src/assets/fonts/Figtree-Light.svg#Figtree-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url("../src/assets/fonts/Figtree-Regular.eot");
  src: url("../src/assets/fonts/Figtree-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/Figtree-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/Figtree-Regular.woff") format("woff"),
    url("../src/assets/fonts/Figtree-Regular.ttf") format("truetype"),
    url("../src/assets/fonts/Figtree-Regular.svg#Figtree-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url("../src/assets/fonts/Figtree-Medium.eot");
  src: url("../src/assets/fonts/Figtree-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/Figtree-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/Figtree-Medium.woff") format("woff"),
    url("../src/assets/fonts/Figtree-Medium.ttf") format("truetype"),
    url("../src/assets/fonts/Figtree-Medium.svg#Figtree-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url("../src/assets/fonts/Figtree-SemiBold.eot");
  src: url("../src/assets/fonts/Figtree-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/Figtree-SemiBold.woff2") format("woff2"),
    url("../src/assets/fonts/Figtree-SemiBold.woff") format("woff"),
    url("../src/assets/fonts/Figtree-SemiBold.ttf") format("truetype"),
    url("../src/assets/fonts/Figtree-SemiBold.svg#Figtree-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url("../src/assets/fonts/Figtree-Bold.eot");
  src: url("../src/assets/fonts/Figtree-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/Figtree-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/Figtree-Bold.woff") format("woff"),
    url("../src/assets/fonts/Figtree-Bold.ttf") format("truetype"),
    url("../src/assets/fonts/Figtree-Bold.svg#Figtree-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transparent-scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  position: absolute;
  right: -10px;
  z-index: 2;
}

.transparent-scrollbar::-webkit-scrollbar * {
  background: transparent;
}

.transparent-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  width: 2px;
  height: 4px;
}
