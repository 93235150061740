@import "/src/styles/variables.scss";

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin letter-spacing {
  letter-spacing: 0.5px;
}

$cursor: pointer;
$font-weight: 700;

header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
}

.logo-container {
  @include flex-center;
  justify-content: center;
  padding: 14px 0;
  box-shadow: 0px 15px 32px 0px #4579ff0f;
}

.tab-container {
  @include flex-center;
  justify-content: space-between;
  padding: 24px 50px 0;
  border-bottom: $tab 1px solid;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  ul {
    @include flex-center;
    @include letter-spacing;
    gap: 24px;
    font-weight: 400;
    list-style-type: none;
    color: $tab;
    padding-left: 24px;

    li {
      cursor: $cursor;
      line-height: 3;
      border: 1px solid transparent;

      &.select {
        color: $Blue_Primary;
        font-weight: $font-weight;
        border-bottom: $Blue_Primary 1px solid;
      }
    }
  }

  .logout-wrap {
    .logout {
      @include letter-spacing;
      cursor: $cursor;
      color: $Blue_Primary;
      font-weight: $font-weight;

      span {
        display: block;
      }

      img {
        display: none;
      }
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    padding: 0 10px;

    ul {
      gap: 10px;

      li {
        font-size: 14px;
      }
    }

    .logout-wrap {
      gap: 10px;

      .logout {
        font-size: 14px;

        span {
          display: none;
        }

        img {
          display: block;
        }
      }
    }
  }
}

.change-password-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 85%);

  .forgotPasswordPageContainer {
    box-shadow: 0px 0px 32px 5px #4579ff0f;
  }
}

@media screen and (max-width:1279px) {
 .tab-container{
  padding: 24px 32px 0;
 } 
}

@media screen and (max-width:767px) {
  .tab-container ul{
    padding-left: 0;
  }
}

@media screen and (max-width:400px) {
  .tab-container{
    padding: 24px 16px 0;
  }
}