@import "../../styles/variables.scss";

.package-page-container {
  height: calc(100vh - 141px);
  max-width: 1280px;
  width: 100%;
  padding: 32px 50px;
  margin: 0 auto;
  background-color: #fff; // Added background color
  border-radius: 12px; // Rounded corners
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); // Subtle shadow

  .package-inner-container {
    padding: 0 20px 0 28px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 46px;
    height: 100%;

    .package-container {
      padding: 24px; // Increased padding for better spacing
      box-shadow: 0 4px 12px rgba(22, 46, 107, 0.05); // Subtle shadow
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      max-width: 300px; // Slightly wider
      width: 100%;
      border-radius: 16px;
      background-color: #f9f9f9; // Light background for contrast

      .title {
        font-size: 22px; // Increased font size
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.04em;
        text-align: center;
        color: $Blue_Primary; // Aligned with brand color
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-align: center;
        color: $davy_gray;
        max-width: 240px;
        margin: 0 auto;
        width: 100%;
      }

      .primary-outline {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        background-color: $white;
        border: 1px solid $Blue_Primary;
        border-radius: 8px;
        color: $Blue_Primary;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: $Blue_Primary;
          color: $white;
        }

        span {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        img {
          display: inline-block;
        }
      }
    }

    .backup-detail-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 100%;
      height: 100%;

      .inline-items {
        display: flex;
        justify-content: space-between;
        align-items: center; // Center vertically
        gap: 16px;

        h2 {
          padding-top: 3px;
          font-size: 24px;
          font-weight: 700;
          line-height: 28.8px;
          text-align: left;
          color: $Blue_Primary; // Aligned with brand color
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        button.primary {
          width: auto;
          padding: 8px 16px;
          gap: 10px;
          border-radius: 8px;
          font-size: 14px; // Increased font size for readability
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.04em;
          text-align: center;
          min-height: 36px;
          height: 36px;
          background-color: $Blue_Primary;
          color: $white;
          border: none;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: darken($Blue_Primary, 10%);
          }
        }
      }

      .backup-listing {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        height: calc(100% - 36px);
        overflow-y: auto;
        padding-right: 10px; // Added padding for scrollbar space

        .backup-container {
          max-width: 248px;
          width: 100%;
          background-color: #f0f0f0; // Light background
          border-radius: 8px;
          padding: 16px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); // Subtle shadow
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }

  // Responsive Styling
  @media screen and (max-width: 1279px) {
    .package-inner-container {
      padding: 0;
      justify-content: space-between;
      max-width: calc(100vw - 64px);
      gap: 32px;

      .package-container {
        min-width: 288px;
      }

      .backup-detail-container {
        max-width: 794px;
        width: auto;

        .backup-listing {
          .backup-container {
            max-width: calc(50% - 12px);
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .package-inner-container {
      .backup-detail-container {
        .backup-listing {
          .backup-container {
            max-width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .package-page-container {
      height: 100%;
      overflow-y: auto;

      .package-inner-container {
        flex-direction: column;
        gap: 32px;
        height: auto;

        .package-container {
          max-width: 100%;
          padding: 32px;

          .description {
            max-width: 320px;
          }

          .button.primary-outline {
            max-width: 320px;
            margin: 0 auto;
          }
        }

        .backup-detail-container {
          height: auto;

          .backup-listing {
            height: 100%;
            overflow-y: hidden;
          }
        }
      }
    }
  }
}
