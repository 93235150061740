@import "../../styles/variables.scss";

.Toastify__toast-container {
  z-index: 999999 !important;
  // right: 9rem !important;
  .Toastify__toast {
    // width: 438px;
    // height: 90px;
  }
  .Toastify__toast-icon {
    width: 40px;
    margin: 0;
  }
  .Toastify__toast-icon + div {
    margin-left: 20px;
    color: $Font_Primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
}
